import { UcoastEl } from '@/scripts/core/UcoastEl'
import { isElementInViewport, qsOptional, qsRequired } from '@/scripts/functions'

export class ParallaxBanner extends UcoastEl {
	static htmlSelector = 'parallax-banner'
	button?: HTMLElement
	observableElement: Element
	observableTopElement: Element
	constructor() {
		super()
		this.observableElement = qsRequired('[data-uc-parallax-observable]', this)
		this.observableTopElement = qsRequired('[data-uc-parallax-observable-top]', this)
		this.button = qsOptional('[data-uc-parallax-button]', this)
	}

	override connectedCallback() {
		super.connectedCallback()
		if (this.button && isElementInViewport(this.button)) {
			this.button.classList.remove('start')
		}
		this.scrollWatch()
	}

	scrollWatch() {
		if (!this.button) return

		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 1.0,
		}

		const bottomObserver = new IntersectionObserver((entries, _) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					this.button?.classList.add('end')
					this.button?.classList.remove('start')
				} else if (this.button && isElementInViewport(this.button)) {
					this.button.classList.remove('end')
				}
			})
		}, options)

		bottomObserver.observe(this.observableElement)

		const topObserver = new IntersectionObserver((entries, _) => {
			entries.forEach((entry) => {
				if (
					entry.isIntersecting &&
					this.observableTopElement.getBoundingClientRect().top < 140
				) {
					this.button?.classList.add('start')
					this.button?.classList.remove('end')
				} else if (this.button && isElementInViewport(this.button)) {
					this.button.classList.remove('start')
				}
			})
		}, options)

		topObserver.observe(this.observableTopElement)
	}
}

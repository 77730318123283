import { UcoastEl } from '@/scripts/core/UcoastEl'
import { getAttributeOrThrow, qsRequired } from '@/scripts/functions'

export class MarqueeSlider extends UcoastEl {
	static htmlSelector = 'marquee-slider'
	private textWrapper: HTMLElement
	private speed: number
	private animation: number | null
	private currentPosition = 0
	private observer: IntersectionObserver

	constructor() {
		super()
		this.textWrapper = qsRequired('[data-uc-marquee-wrapper]')
		try {
			this.speed = parseInt(getAttributeOrThrow('data-speed', this)) / 10
		} catch (e) {
			this.speed = 0.2
		}
		this.animation = null

		this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
			threshold: 0,
		})
		this.observer.observe(this)
	}

	private startMarquee(): void {
		this.animation = requestAnimationFrame(this.moveItems.bind(this))
	}

	private moveItems() {
		this.currentPosition -= this.speed

		if (this.textWrapper.getBoundingClientRect().right < window.innerWidth) {
			this.currentPosition = 0
		}

		this.textWrapper.style.transform = `translateX(${this.currentPosition}px)`
		this.animation = requestAnimationFrame(this.moveItems.bind(this))
	}

	stopMarquee(): void {
		if (this.animation !== null) {
			cancelAnimationFrame(this.animation)
			this.animation = null
			this.currentPosition = 0
		}
	}

	private handleIntersection(entries: IntersectionObserverEntry[]): void {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				this.startMarquee()
			} else {
				this.stopMarquee()
			}
		})
	}
}

import { globalSetup, initShopify } from '@/scripts/setup'
import { HeaderMenu } from '@/scripts/content/details-disclosure'
import { DetailsModal } from '@/scripts/content/details-modal'
import { MediaGallery } from '@/scripts/media/media-gallery'
import { HeaderDrawer } from '@/scripts/layout/HeaderDrawer'
import { MenuDrawer } from '@/scripts/layout/MenuDrawer'
import { DeferredMedia } from '@/scripts/media/deferred-media'
import { SliderComponent, SlideshowComponent } from '@/scripts/media/sliders'
import { uCoastWindow } from '@/scripts/setup'
import { safeDefineElement } from '@/scripts/functions'
import { StickyHeader } from '@/scripts/layout/StickyHeader'
import { SlideDrawer } from '@/scripts/layout/SlideDrawer'
import { DynamicContent } from '@/scripts/content/dynamic-content'
import { GenericSection } from '@/scripts/core/GenericSection'
import { MarqueeSlider } from '@/scripts/content/marquee-slider'
import { ParallaxBanner } from '@/scripts/media/parallax-banner'
import { UcoastVideo } from '@/scripts/media/ucoast-video'
import { HlsLoader } from '@/scripts/media/hls-loader'

declare let window: uCoastWindow

function initialize() {
	globalSetup()
	// Shopify window stuff
	if (typeof window.Shopify == 'undefined') {
		window.Shopify = initShopify()
	}
	// define custom elements
	safeDefineElement(UcoastVideo) // must come before HlsLoader
	safeDefineElement(HlsLoader) // must come after UcoastVideo
	safeDefineElement(GenericSection)
	safeDefineElement(StickyHeader)
	safeDefineElement(HeaderMenu)
	safeDefineElement(DetailsModal)
	safeDefineElement(MenuDrawer)
	safeDefineElement(HeaderDrawer)
	safeDefineElement(DeferredMedia)
	safeDefineElement(MediaGallery)
	safeDefineElement(SliderComponent)
	safeDefineElement(SlideshowComponent)
	safeDefineElement(SlideDrawer)
	safeDefineElement(DynamicContent)
	safeDefineElement(MarqueeSlider)
	safeDefineElement(ParallaxBanner)
	// this needs to be last
	/*if (window.Shopify.designMode) {
		initializeThemeEditor()
	}*/
}

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize)
} else {
	initialize()
}

export const shopify = window.Shopify
